<ul style="font-family: Helvetica;">
    <li style="float: left; width: 40px;">
        <a class="home-logo" href="/">
        </a>
    </li>
    <li>
        <a skipLocationChange routerLink="/Contact">Contact</a>
    </li>
    <li class="seperator">|</li>
    <li *ngIf="isPJG"> 
        <a skipLocationChange routerLink="/Construction">Galerij</a>
    </li>
    <li *ngIf="isPJG" class="seperator">|</li>
    <li *ngIf="isPJG">
        <a skipLocationChange routerLink="/Construction">Tarieven</a>
    </li>
    <li *ngIf="isThor">
        <a skipLocationChange routerLink="/Construction">Over Thor</a>
    </li>
    <li *ngIf="isThor" class="seperator">|</li>
    <li *ngIf="isThor">
        <a skipLocationChange routerLink="/Portfolio">Portfolio</a>
    </li>
</ul>