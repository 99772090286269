<ul>
    <li style="float: left; width: 40px;">
        <a class="home-logo" href="/">
        </a>
    </li>
    <li>
        <a href="/TCG">Trading Card Games</a>
    </li>
    <li>
        <a href="/Construction">Contact</a>
    </li>
    <li>
        <a href="/Construction">Over mij</a>
    </li>
    <li>
        <a href="/Construction">Portfolio</a>
    </li>
</ul>