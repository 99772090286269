import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: false
})

export class MenuComponent implements OnInit {
  isPJG: boolean = false;
  isThor: boolean = false;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      console.log(event);
      if (this.router.url.toLowerCase() == "/thor") {
        this.isThor = true;
        this.isPJG = false;
      }
      
      if (this.router.url.toLowerCase() == "/pjg-alters") {
        this.isThor = false;
        this.isPJG = true;          
      }
    }) 
  }

  ngOnInit(): void {
  }
}
