import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutMeComponent } from './pages/about-me/about-me.component';
import { ConstructionComponent } from './pages/construction/construction.component';
import { HomeComponent } from './pages/home/home.component';
import { MtgComponent } from './pages/mtg/mtg.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { ThorComponent } from './pages/thor/thor.component';
import { ContactComponent } from './pages/contact/contact.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'Construction', component: ConstructionComponent },
  { path: 'Thor', component: ThorComponent},
  { path: 'About', component: AboutMeComponent },
  { path: 'Portfolio', component: PortfolioComponent },
  { path: 'Contact', component: ContactComponent },
  { path: 'PJG-alters', component: MtgComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
